<template>
    <div class="mobile-page-layout">

        <div class="mobile-page-header" style="border-bottom: 1px solid #e6e6e6;">
            <div class="mobile-page-header-row">
                <div class="mobile-page-header-right">
                    <router-link :to="{ name: 'EconomicsCountryListCategory'}" class="mobile-page-header-icon pointer back-icon">
                        <i class="header-uil-icon uil uil-angle-right-b"></i>
                    </router-link>
                    <div class="mobile-page-header-text">
                        شاخص‌ها - {{ title }}
                    </div>  
                </div>
                <div class="mobile-page-header-actions">  
                    <div class="header-actions-sw">
                        <div :class="'header-actions-sw-item ' + (typeList == 1 ? 'sw-item-active' : '')" @click="typeList = 1">فعلی</div>
                        <div :class="'header-actions-sw-item ' + (typeList == 2 ? 'sw-item-active' : '')" @click="typeList = 2">پیش‌بینی</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="profile-mobile-view" style="overflow: auto;border-bottom: 1px solid #eaeaea;">
            <v-select v-model="selectCountryList" @change="toggle" class="profile-mobile-view-select" style="width: auto;margin: 5px 15px 10px !important;" :items="items" item-text="name"  item-value="type" label="انتخاب شاخه" outlined></v-select>
        </div>
    
        <slot v-if="loading">
            <div class="tgju-widget-content full-padding">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </div>
        </slot>

        <div class="market-main-item" v-for="(item, index) in countryList" :key="index" v-if="countryList && !loading">
            
            <div class="market-main-item-row" style="background: #eaeaea;">
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-title">
                        
                        <h2 style="background: #fff;padding: 4px 10px; border-radius: 4px;  font-weight: 600;font-size: 12px;"><div :class="'country-box-select-flag mini-flag flag-'+ changeTab(item.country)"></div> {{ item.title }}</h2>
                    </div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-price" style="white-space: nowrap;display: block;direction: ltr;"> <i class="uil uil-clock" style="margin-right: 3px;"></i><span>{{ item.data.date.year +'/'+ item.data.date.day }}</span> </div>
                </div>
            </div>
            <div :class="'market-main-item-row ' + (item.value < 0 ? 'i-row-low' : '')"  v-if="item.value">
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-time"><i class="uil uil-clock-eight"></i><span>آخرین </span></div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-price" style="white-space: nowrap;display: block;"> <span>{{ formatPrice(item.value) }}</span> </div>
                </div>
            </div>
            <div :class="'market-main-item-row ' + (item.data.prev < 0 ? 'i-row-low' : '')"  v-if="item.data.prev">
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-time"><i class="uil uil-clock-eight"></i><span>فعلی </span></div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-price" style="white-space: nowrap;display: block;"> <span>{{formatPrice(item.data.prev)}}</span></div>
                </div>
            </div>

            <div :class="'market-main-item-row ' + (item.data.q1 < 0 ? 'i-row-low' : '')"  v-if="item.data.q4 && typeList == 2">
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-time"><i class="uil uil-file"></i><span>فصل‌اول </span></div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-price" style="white-space: nowrap;display: block;"> <span>{{formatPrice(item.data.q1)}}</span></div>
                </div>
            </div>

            <div :class="'market-main-item-row ' + (item.data.q2 < 0 ? 'i-row-low' : '')"  v-if="item.data.q4 && typeList == 2">
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-time"><i class="uil uil-file"></i><span>فصل‌دوم </span></div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-price" style="white-space: nowrap;display: block;"> <span>{{formatPrice(item.data.q2)}}</span></div>
                </div>
            </div>

            <div :class="'market-main-item-row ' + (item.data.q3 < 0 ? 'i-row-low' : '')"  v-if="item.data.q4 && typeList == 2">
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-time"><i class="uil uil-file"></i><span>فصل‌سوم </span></div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-price" style="white-space: nowrap;display: block;"> <span>{{formatPrice(item.data.q3)}}</span></div>
                </div>
            </div>

            <div :class="'market-main-item-row ' + (item.data.q4 < 0 ? 'i-row-low' : '')"  v-if="item.data.q4 && typeList == 2">
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-time"><i class="uil uil-file"></i><span>فصل‌چهارم </span></div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-price" style="white-space: nowrap;display: block;"> <span>{{formatPrice(item.data.q4)}}</span></div>
                </div>
            </div>


            <div class="market-main-item-row" >
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-time"><i class="uil uil-calculator-alt"></i><span> واحد اندازه گیری </span></div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-change" style="white-space: nowrap;display: block;">{{ item.data.metric}}</div>
                </div>
            </div>

        </div>

    </div>

</template>


<script>
import moment from "moment-jalaali";
import static_country from "@/assets/static_country";
import category_list from "@/assets/static_country_list_category";

export default {
    name: 'CountryListView',
    component: {
    },
    data: function () {
        return {
            countryList: {},
            static_country,
            category_list,
            selectCountryList: null,
            type: null,
            category: this.$route.params.category ?? 'job',
            loading: false,
            typeList: 1,
            searchQuery:'',
            search:false,
            items: null,
            title: '',
        }
    },
    beforeMount() {
        this.items = this.category_list[this.category].items;
        this.type = this.$route.params.type ?? this.items[0].type;
        this.selectCountryList = this.type ?? this.items[0];
        this.title = this.category_list[this.category].name;
    },
    mounted() {
        // دریافت اطلاعات تحلیل های تایید شده ی عمومی
        this.loadData();
    },
    computed: {
        filteredList() {
            if(this.searchQuery === '') {
                return this.localCryptoExchanges;
            }
            return Object.values(this.localCryptoExchanges).filter(Exchange => {
                return Exchange.category_title.toLowerCase().includes(this.searchQuery.toLowerCase())
            })
        },
    },
    methods: {
        
        changeTab(param){
            let flag = 'def';
            this.static_country.country_locations.find((o, i) => {
                if (o.name.toLowerCase().trim() == param.toLowerCase().replaceAll('-',' ').trim()) {
                    flag = o.code.toLowerCase();
                }
            });
            return flag;
        },
        toggle() {
            this.type = this.selectCountryList;
            this.loadData();
        },
        formatPrice(value) {
            if (value === null) {
                return '0';
            }

            value = parseFloat(value);
            return parseFloat(value.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        // نمایش زمان بصورت 1401/03/12
        to_yyyy_mm_dd(date) {
            return moment(date).format("H:m:s - jYYYY/jMM/jDD");
        },
        loadData() {
            this.loading = true;
            this.$helpers.makeRequest('POST', '/economics/country-list', {
                type : this.type,
            }).then(api_response => {
                if ( api_response.status == 200 ) {
                    this.countryList = JSON.parse(api_response.data).response;
                    this.loading = false;
                }
            });
        }
    }
}
</script>
